import { Location, isPlatformBrowser } from "@angular/common";
import { Component, Inject, LOCALE_ID, PLATFORM_ID } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { LanguageService } from "src/app/_services/language.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'language-selector',
  templateUrl: './language.component.html',
})
export class LanguageComponent {
  
  selectedLanguageCountry: string;
  currentURI: string;
  
  private static LANGUAGE_TO_COUNTRY: Map<string, string> = new Map([
    ['et', 'ee'],
    ['ru', 'ru'],
    ['en-US', 'gb']
  ]);
    
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private location: Location,
    private languageService: LanguageService,
    @Inject(LOCALE_ID) locale: string,
    ) {

    const assetsURL = environment.assetsBaseURL;
              
    iconRegistry.addSvgIcon('flag-gb', sanitizer.bypassSecurityTrustResourceUrl(`${assetsURL}/svg-country-flags/svg/gb.svg`));  
    iconRegistry.addSvgIcon('flag-ee', sanitizer.bypassSecurityTrustResourceUrl(`${assetsURL}/svg-country-flags/svg/ee.svg`));
    iconRegistry.addSvgIcon('flag-ru', sanitizer.bypassSecurityTrustResourceUrl(`${assetsURL}/svg-country-flags/svg/ru.svg`));
      
    this.router.events.subscribe(() => {
      this.currentURI = this.location.path();
    });
    
    this.selectedLanguageCountry = LanguageComponent.LANGUAGE_TO_COUNTRY.get(locale)!;
  }
  
  select(language: string) {
    this.languageService.storePreferredLanguage(language);
    window.location.href = `/${language}${this.currentURI}`;
  }
}
